import { Box, Grid, List, ListItem, Typography, useMediaQuery, useTheme } from "@mui/material";
import Image from "next/image";
import logo from "@public/images/lp/logo.png";
import logoMobile from "@public/images/lp/LogoMobile.svg";
import PhoneIcon from "@components/lp/common/icons/phone";
import EmailIcon from "@components/lp/common/icons/email";
import Link from "next/link";
import { footer_navigation } from "@constants/lp/footer";

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:640px)");

  return (
    <Box>
      <Box sx={{ backgroundColor: "background.gray", padding: { lg: "66px 80px", md: "40px", xs: "40px 24px" } }}>
        <Grid
          container
          spacing={6}
          sx={{
            m: 0,
            justifyContent: "space-between",
            maxWidth: "1920px",
            margin: "0 auto"
          }}
        >
          <Grid item lg={4}>
            <Image src={isMobile ? logoMobile : logo} alt='logo' height={46} width={219} />

            <Typography
              sx={{
                maxWidth: "600px",
                color: "text.greyLight",
                fontSize: { md: "18px", xs: "14px" },
                fontWeight: 500,
                lineHeight: { md: "36px", xs: "24px" },
                letterSpacing: "0.54px",
                mt: { md: "26px", xs: "10px" },
                mb: "33px",
                pr: "24px"
              }}
            >
              We empower your business with the tools needed to maximize your data&apos;s potential. Our intuitive
              dashboard transforms complex data into actionable insights, simplifying data management and enabling
              data-driven decision-making. We believe data is your most valuable asset and are passionate about helping
              you leverage it for success.
            </Typography>

            <Box sx={{ mb: "30px" }}>
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  mr: "70px",
                  mb: "20px",
                  fontSize: { md: "18px", xs: "14px" }
                }}
              >
                <PhoneIcon
                  color={theme.palette.text.primary}
                  width={isMobile ? "30" : "48"}
                  height={isMobile ? "30" : "48"}
                />
                <Link
                  href={`tel:+1 833 641 2200`}
                  style={{
                    textDecoration: "none",
                    marginLeft: "15px",
                    color: theme.palette.text.greyDark,
                    fontSize: { md: "18px", xs: "12px" },
                    fontWeight: 450
                  }}
                >
                  +1 833 641 2200
                </Link>
              </Box>

              <Box sx={{ display: "inline-flex", alignItems: "center", fontSize: { md: "18px", xs: "14px" } }}>
                <EmailIcon
                  color={theme.palette.text.primary}
                  width={isMobile ? "30" : "48"}
                  height={isMobile ? "30" : "48"}
                />

                <Link
                  href={`mailto:leads@vigorant.com`}
                  style={{
                    textDecoration: "none",
                    marginLeft: "15px",
                    color: theme.palette.text.greyDark,
                    fontWeight: 450
                  }}
                >
                  leads@vigorant.com
                </Link>
              </Box>
            </Box>
          </Grid>

          <Grid item lg={7}>
            <Grid container rowSpacing={4} columnSpacing={12}>
              {footer_navigation.map((item, index) => (
                <Grid item key={index}>
                  <Typography
                    sx={{
                      color: "text.greyDark",
                      fontSize: { md: "24px", xs: "18px" },
                      fontWeight: 500,
                      lineHeight: { md: "43px", xs: "36px" },
                      letterSpacing: "0.72px"
                    }}
                  >
                    {item.name}
                  </Typography>
                  <List>
                    {item.list.map((listItem, index) => (
                      <ListItem key={index} sx={{ p: 0 }}>
                        <Link href={listItem.link} target='_blank' style={{ textDecoration: "none" }}>
                          <Typography
                            sx={{
                              color: "text.greyLight",
                              fontSize: { md: "20px", sm: "16px", xs: "12px" },
                              fontWeight: 459,
                              lineHeight: { md: "43px", xs: "36px" },
                              letterSpacing: "0.6px",
                              "&:hover": { color: "text.primary" }
                            }}
                          >
                            {listItem.title}
                          </Typography>
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ padding: "36px", textAlign: "center", backgroundColor: "background.whiteLight" }}>
        <Typography sx={{ fontSize: "14px", fontWeight: 450, color: "text.primary" }}>
          Copyright © 2024 Vigorant inc. | Designed and Developed by: Infident, Inc.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
